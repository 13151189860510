import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './NavbarStyles.css'
import MciLogo from '../assets/logomci.png';

function Navbar(){
    const [menuOpen, setMenuOpen] = useState(false)

    return(
        <>
            <nav>
                <Link to ="/"><img style={{ width: 90, height: 90 }} src={MciLogo} alt="MCI Logo" className="logo" /></Link>

                <div className="menu" onClick={() =>{setMenuOpen(!menuOpen);}}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                    <ul className={menuOpen ? "open" : ""}>
                        <li>
                            <NavLink to="/about">About</NavLink>
                        </li>

                        <li>
                            <NavLink to="/catalog">Catalog</NavLink>
                        </li>
                    </ul>
                
            </nav>
        </>
    )

    
}

export default Navbar