import React from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import { IoChevronBack } from "react-icons/io5";
import project1 from '../assets/20230721_115332.jpg'
import project2 from '../assets/project2.png';
import project3 from '../assets/project3.png';
import project4 from '../assets/project4.png';
import singapadu1 from '../assets/20220705 SINGAPADU 1BD Villa 1st PRES-25.png'
import exterior1 from '../assets/exterior1.png'
import exterior2 from '../assets/exterior2.png'
import exterior3 from '../assets/exterior3.png'

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true
        }
      },{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true
        }
      }
    ]
};

const Exterior = () => {
    return (
      <div
        style={{
          // backgroundColor:'rgb(0,0,0, 0.4)',
          background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '70vh',
        }}
      >
        
        
        <div className="container">

          <Link to="/">
            <div className="back-icon">
              <IoChevronBack size={56}/>
            </div>
          </Link>

          <div className="text-title">
            <h1>Civil & Architectural</h1>
          </div>

          <div className="text-header">
            <h3>Our Civil & Architectural Design</h3>
          </div>
  
        <div className="work-container">
            <Slider {...settings}>
                
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={singapadu1} alt="singapadu 1"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={exterior1} alt="exterior 1"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={project1} alt="project 1"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={project2} alt="project 2"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={exterior2} alt="exterior 2"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={project4} alt="project 4"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={exterior3} alt="exterior 3"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={project3} alt="project 3"/>
                </div>
                
            </Slider>
        </div>
  
          <div className="text-header">
            <h3>Office . House . Villa . Hotel</h3>
          </div>
        
        </div>
      </div>
    );
};
    
export default Exterior;