import React from 'react';
import {Link} from 'react-router-dom';
import volex1 from '../assets/volex1.png';
import volex2 from '../assets/volex2.png';
import volexcable from '../assets/volexcable.png'
import { IoChevronBack } from "react-icons/io5";

const Volex = () => {
  return (
    <div
      style={{
        background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div>
        <Link to="/catalog">
          <div className="back-icon">
            <IoChevronBack size={56}/>
          </div>
        </Link>
        
        <div className="text-title">
          <h1>Volex</h1>
        </div>
        <div className="text-header">
          <h3>Leading high quality cable and wire with various uses. The official partner for Tesla as the charging cable manufacturer</h3>
          {/* <h3>Certified Multiuse Cable</h3>
          <h3>Official Tesla Charging Cable Manufacturer</h3> */}
        </div>

        
        <div className="text-title">
          <h2>On Stock Items</h2>
        </div>

        <div className="catalog-products">
          <div className="product-row">
            <img  className="row-images" src={volex1} alt="volex 1"/>
            <img  className="row-images" src={volexcable} alt="volex cable"/>   
            <img  className="row-images" src={volex2} alt="volex 2"/>
           </div>
          <h3>Ready use cable made by order</h3>

        </div>

      </div>

    </div>
  );
};
  
export default Volex;