import React from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import { IoChevronBack } from "react-icons/io5";
import interior1 from '../assets/interior1.png';
import interior2 from '../assets/interior2.png';
import interior3 from '../assets/interior3.png';
import interior4 from '../assets/interior4.png';
import interior5 from '../assets/interior5.png';
import interior6 from '../assets/interior6.png';
import interior7 from '../assets/interior7.png';
import interior8 from '../assets/interior8.png';
import interior9 from '../assets/interior9.png';
import interior10 from '../assets/interior10.png';
import interior11 from '../assets/interior11.png';
import interior12 from '../assets/interior12.png';
import interior13 from '../assets/interior13.png';
import interior14 from '../assets/interior14.png';
import interior15 from '../assets/interior15.png';

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true
        }
      },{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true
        }
      }
    ]
};

const Interior = () => {
    return (
      <div
        style={{
          // backgroundColor:'rgb(0,0,0, 0.4)',
          background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '70vh',
        }}
      >
        
        
        <div className="container">

          <Link to="/">
            <div className="back-icon">
              <IoChevronBack size={56}/>
            </div>
          </Link>

          <div className="text-title">
            <h1>Interior</h1>
          </div>
          
          <div className="text-header">
            <h3>Our Interior Design</h3>
          </div>
  
        <div className="work-container">
            <Slider {...settings}>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior4} alt="interior 4"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior2} alt="interior 2"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior8} alt="interior 8"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior9} alt="interior 9"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior7} alt="interior 7"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior10} alt="interior 10"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior3} alt="interior 3"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior11} alt="interior 11"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior5} alt="interior 5"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior1} alt="interior 1"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior6} alt="interior 6"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior12} alt="interior 12"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior13} alt="interior 13"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior14} alt="interior 14"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={interior15} alt="interior 15"/>
                </div>
                
            </Slider>
        </div>
  
          <div className="text-header">
            <h3>House Interior . Villa Interior . Office Interior</h3>
          </div>
        
        </div>
      </div>
    );
};
    
export default Interior;