import React from 'react';
import {Link} from 'react-router-dom';
import volexlogo from '../assets/volex.png'
import nanologo from '../assets/nanoguard.png'
import nipponlogo from '../assets/Nippon-Paint-Logo.png'
  

const Catalog = () => {

  return (
    <div style={{
        background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)'
      }}>
      <div>
        <div className="text-title">
          <h1>Our Products</h1>
        </div>

        <div className="catalog">
          <div className="volex">
            <Link to= "/volex">
              <img  className="product-images" style={{height:200, width:300}} src={volexlogo} alt="ng 1"/>
            </Link>
            <p>Volex</p>
          </div>

          <div className="nanoguard">
            <Link to= "/nanoguard">
              <img className="product-images" style={{height:200, width:300}} src={nanologo} alt="ng 1"/>
            </Link>
            <p>Nano Guard</p>
          </div>

          <div className="nippon">
            <Link to= "/nipponpaint">
              <img className="product-images" style={{height:200, width:300}} src={nipponlogo} alt="ng 1"/>
            </Link>
            <p>Nippon Paint</p>
          </div>

        </div>
      </div>
    </div>
  );
};
  
export default Catalog;