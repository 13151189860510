import React from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import Medifresh3in1 from "../assets/3in1medifresh.png"
import Odourless5101 from "../assets/5101odourless.png"
import Bodelac9000 from "../assets/bodelac9000.png"
import Easywash from "../assets/easywash.png"
import Expresskote from "../assets/expresskote.png"
import Matex from "../assets/matex.png"
import Roadlinepaint from "../assets/roadlinepaint.png"
import Vinilex5000 from "../assets/vinilex5000.png"
import Vinilex5170 from "../assets/vinilex5170.png"
import Vinylsilk from "../assets/vinylsilk.png"
import Weatherbond from "../assets/weatherbond.png"
import { IoChevronBack } from "react-icons/io5";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        infinite: true,
        dots: true
      }
    },{
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: true
      }
    }
  ]
};


  
const Nippon = () => {
  return (
    <div
      style={{
        // backgroundColor:'rgb(0,0,0, 0.4)',
        background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      
      
      <div className="container">

        <Link to="/catalog">
          <div className="back-icon">
            <IoChevronBack size={56}/>
          </div>
        </Link>

        <div className="text-title">
          <h1>Nippon Paint</h1>
        </div>

        <div className="text-header">
          <h3>Number one paint manufacturer in Asia</h3>
        </div>

        <div className="work-container">
          <Slider {...settings}>

            <div className="card">
              <img className='image-card' src={Medifresh3in1} alt="Medifresh 3 in 1"/>
              <div className="card-body">
                <div className="card-title">3in1 Medifresh</div>
                <div className="card-text">Anti-Bacterial Protection</div>
                <div className="card-text">Interior use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Odourless5101} alt="Odourless 5101"/>
              <div className="card-body">
                <div className="card-title">5101 Odour-less</div>
                <div className="card-text">Water-Based Sealer</div>
                <div className="card-text">Interior use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Bodelac9000} alt="Bodelac 9000"/>
              <div className="card-body">
                <div className="card-title">Bodelac 9000</div>
                <div className="card-text">High Gloss Enamel Paint</div>
                <div className="card-text">Wood & Metal use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Easywash} alt="Easy Wash"/>
              <div className="card-body">
                <div className="card-title">Easy Wash</div>
                <div className="card-text">Premium Washable Paint</div>
                <div className="card-text">Interior use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Expresskote} alt="Expresskote"/>
              <div className="card-body">
                <div className="card-title">Expresskote</div>
                <div className="card-text">Water-Based Sealer</div>
                <div className="card-text">Interior & Exterior use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Matex} alt="Matex"/>
              <div className="card-body">
                <div className="card-title">Matex</div>
                <div className="card-text">Emulsion Paint Fungus Resistant</div>
                <div className="card-text">Interior use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Roadlinepaint} alt="Roadline Paint"/>
              <div className="card-body">
                <div className="card-title">Roadline Paint</div>
                <div className="card-text">Traffic Marking Paint</div>
                <div className="card-text">Asphalt & Concrete Floor Use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Vinilex5000} alt="Vinilex 5000"/>
              <div className="card-body">
                <div className="card-title">Vinilex 5000</div>
                <div className="card-text">Most Economical Paint</div>
                <div className="card-text">Interior Use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Vinilex5170} alt="Vinilex 5170"/>
              <div className="card-body">
                <div className="card-title">Vinilex 5170</div>
                <div className="card-text">Oil-Based Sealer</div>
                <div className="card-text">Interior & Exterior Use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Vinylsilk} alt="Vinyl Silk"/>
              <div className="card-body">
                <div className="card-title">Vinyl Silk</div>
                <div className="card-text">Luxurious Silky Shades</div>
                <div className="card-text">Interior Use</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={Weatherbond} alt="Weatherbond"/>
              <div className="card-body">
                <div className="card-title">Weatherbond</div>
                <div className="card-text">Exceptionally Weatherable Paint</div>
                <div className="card-text">Exterior Use</div>
              </div>
            </div>

          </Slider>
        </div>

        <div className="text-header">
          <h3>The only paint you need</h3>
        </div>
      
      </div>
    </div>
  );
};
  
export default Nippon;