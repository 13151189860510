import React from 'react';
import {Link} from 'react-router-dom';
import ng1 from '../assets/ng1.png'
import ng2 from '../assets/ng2.png'
import ng3 from '../assets/ng3.png'
import ngdeck2 from '../assets/ngdeck2.png'
import ngtile2 from '../assets/ngtile2.png'
import { IoChevronBack } from "react-icons/io5";
  
const Nanoguard = () => {
  return (
    <div
      style={{
        // backgroundColor:'rgb(0,0,0, 0.4)',
        background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div className="container">

        <Link to="/catalog">
          <div className="back-icon">
            <IoChevronBack size={56}/>
          </div>
        </Link>

        <div className="text-title">
          <h1>Nano Guard</h1>
        </div>

        <div className="catalog-products">
          <h2>What Is Nano Guard?</h2>
          <div className="product-row">
            <img  className="row-images" src={ng1} alt="ng 1"/>
            <img  className="row-images" src={ng2} alt="ng 2"/>
            <img  className="row-images" src={ng3} alt="ng 3"/>
          </div>

          <div className="text-title">
            <h2>On Stock Items</h2>
          </div>
          <div className="product-row">

            <div className="card">
              <img className='image-card' src={ngdeck2} alt="ng deck"/>
              <div className="card-body">
                <div className="card-title">Nano Guard Deck</div>
                <div className="card-text">Waterproofing for concrete deck.</div>
                <div className="card-text">Best used on Concrete Deck, Concrete Plaster, Marmer, Travertine.</div>
              </div>
            </div>

            <div className="card">
              <img className='image-card' src={ngtile2} alt="ng tile"/>
              <div className="card-body">
                <div className="card-title">Nano Guard Tile</div>
                <div className="card-text">Waterproofing for ceramic tile.</div>
                <div className="card-text">Best used on Bathroom, Kitchen, Laundry Room.</div>
              </div>
            </div>

          </div>

        </div>
        <div className="text-header">
          <h3>The solution for water leaking</h3>
        </div>
      </div>
    </div>
  );
};
  
export default Nanoguard;