import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from './pages/index'
import About from './pages/about'
import Catalog from './pages/catalog'
import Volex from './pages/volex';
import Nanoguard from './pages/nanoguard';
import Nippon from './pages/nipponpaint';
import Exterior from './pages/exterior';
import Interior from './pages/interior';
import Industrial from './pages/industrial';

import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/catalog" element={<Catalog />}/>
        <Route path="/volex" element={<Volex />}/>
        <Route path="/nanoguard" element={<Nanoguard />}/>
        <Route path="/nipponpaint" element={<Nippon />}/>
        <Route path="/exterior" element={<Exterior />}/>
        <Route path="/interior" element={<Interior />}/>
        <Route path="/industrial" element={<Industrial />}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
