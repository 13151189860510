import React from "react";
import './FooterStyles.css'
import { BiLogoFacebook, BiLogoWhatsapp } from "react-icons/bi";


function Footer(){
    return (
        <>
            <div className="row">
                <div className="footerlink">
                    <div className="column">
                        <a href="https://facebook.com">
                            <BiLogoFacebook size={28}/>
                        </a>
                    </div>

                    <div className="column">
                        <a href="https://wa.me/+6281261315511">
                            <BiLogoWhatsapp size={28}/>
                        </a>
                    </div>
                </div>

                <div className="copyright">
                    <h3>Copyright © 2023 Mega Contractor Indonesia. All Rights Reserved</h3>
                    <h5>Ruko Mahkota Niaga Blok B No.3, Jl. Raja Isa, Baloi Permai, Kec. Batam Kota, Kota Batam, Kepulauan Riau 29431</h5>
                </div>
            
            </div>
        </>
    )
}

export default Footer;

                            
                            
