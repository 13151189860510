import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as Buildings} from '../assets/building-2-svgrepo-com.svg';
import {ReactComponent as Interior} from '../assets/interior-svgrepo-com.svg';
import {ReactComponent as Supply} from '../assets/supply-chain-inventory-manufacturing-supply-svgrepo-com.svg';
import {ReactComponent as Warehouse} from '../assets/warehouse-svgrepo-com.svg';
import background from "../assets/construction-workers-sunset.jpg";
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ['Contractor', 'Interior', 'Supplier'];
  
const Home = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        height: '90vh',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover'
      }}
    >
      <div className="container-index">

      <div className="text-header-index">
        <p>Mega Contractor Indonesia</p>
      </div>
      
      <div className="text">
        <p>
          <TextTransition springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>
        </p>
      </div>

      <div className="index-background">
        <Link to ="/exterior">
          <Buildings className="index-background-icon" />
        </Link>

        <Link to ="/interior">
          <Interior className="index-background-icon" />
        </Link>
      </div>
        
      <div className="index-background"> 
        <Link to ="/industrial">
          <Warehouse className="index-background-icon" />
        </Link>
        
        <Link to ="/catalog">
          <Supply className="index-background-icon" />
        </Link>

      </div>
      </div>
      
    </div>
  );
};
  
export default Home;