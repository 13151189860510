import React from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';
import { IoChevronBack } from "react-icons/io5";
import warehouse1 from '../assets/warehouse1.png'
import warehouse2 from '../assets/warehouse2.png'
import warehouse3 from '../assets/warehouse3.png'
import warehouse4 from '../assets/warehouse4.png'
import project5 from '../assets/project5.png';
import project6 from '../assets/project6.png';
import warehouse5 from '../assets/warehouse5.png'
import warehouse6 from '../assets/warehouse6.png'
import warehouse7 from '../assets/warehouse7.png'

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true
        }
      },{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true
        }
      }
    ]
};

const Industrial = () => {
    return (
      <div
        style={{
          // backgroundColor:'rgb(0,0,0, 0.4)',
          background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          height: '70vh',
        }}
      > 
        <div className="container">

          <Link to="/">
            <div className="back-icon">
              <IoChevronBack size={56}/>
            </div>
          </Link>

          <div className="text-title">
            <h1>Industrial</h1>
          </div>
          
          <div className="text-header">
            <h3>Industrial Projects</h3>
          </div>
  
        <div className="work-container">
            <Slider {...settings}>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={project5} alt="project 5"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse1} alt="warehouse 1"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={project6} alt="project 6"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse2} alt="warehouse 2"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse3} alt="warehouse 3"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse4} alt="warehouse 4"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse7} alt="warehouse 7"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse5} alt="warehouse 5"/>
                </div>
                <div className="carousel-images">
                    <img className='image-carousel' style={{height:200, width:300}} src={warehouse6} alt="warehouse 6"/>
                </div>
            </Slider>
        </div>
  
          <div className="text-header">
            <h3>Warehouse . Workshop . Dock</h3>
          </div>
        
        </div>
      </div>
    );
};
    
export default Industrial;