import React from 'react';
import Slider from "react-slick";
import interior1 from '../assets/interior1.png';
import interior3 from '../assets/interior3.png';
import interior5 from '../assets/interior5.png';
import project1 from '../assets/20230721_115332.jpg'
import project2 from '../assets/project2.png';
import project3 from '../assets/project3.png';
import project4 from '../assets/project4.png';
import project5 from '../assets/project5.png';
import project6 from '../assets/project6.png';
import singapadu1 from '../assets/20220705 SINGAPADU 1BD Villa 1st PRES-25.png'

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
        infinite: true,
        dots: true
      }
    },{
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: true
        
      }
    }
  ]
};
  
function About() {
  return (
    <div style={{
        // backgroundColor:'rgb(0,0,0, 0.4)'
        background:'linear-gradient(to bottom, rgba(0, 0, 0, 0.4), #3498db)'
      }}>
      <div>
        <div className="text-title">
          <h1>About Us</h1>
        </div>

        <div className="text-profile">
          <h2>Mega Contractor Indonesia is a construction service company that have been operated since 2007 that is based in Batam, Indonesia. With a vision "To become a great construction company which bless the nations" we always "Focus and give the best services in the construction" which is our mission, in order to give satisfaction to our customers. While giving the best in construction work, we also provides some construction materials and apply it for you. </h2>
        </div>

        <div className="text-title">
          <h1>Our Work</h1>
        </div>
        <div className="work-container">
          <Slider {...settings}>
              <div className="carousel-images">
                <img className='image-carousel' src={project5} alt="project 5"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={singapadu1} alt="singapadu 1"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={interior3} alt="interior 3"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={interior5} alt="interior 5"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={project1} alt="project 1"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={project2} alt="project 2"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={project3} alt="project 3"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={project4} alt="project 4"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={interior1} alt="interior 1"/>
              </div>
              <div className="carousel-images">
                <img className='image-carousel' src={project6} alt="project 6"/>
              </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
  
export default About;